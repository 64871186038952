<template>
  <form @submit.prevent="publishMarket" class="bg-light pa-5">

    <div class="row">
      <div class="col-md-4">
        <CoverUpdate :cover="cover" :qrcbn="qrcbn"></CoverUpdate>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="dialog-form px-5 mb-5">
              <span class="mb-3">PDF File</span>
              <v-btn block outlined color="primary" @click="onButtonClick" :disabled="submitImage">
                <span v-if="!fileName">Select Ebook File</span>
                <span v-else>{{ fileName }}</span>
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".pdf" @change="onFileChanged" required/>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="dialog-form px-5 mb-5">
              <span class="mb-3">Price</span>
              <v-text-field solo hide-details="auto" type="number" prefix="Rp." v-model="price" required></v-text-field>
            </div>
          </div>

          <div class="col-12 col-md-12 px-10">
            <h1 class="h3 fw-bold mb-2">Pilih Platform Penjualan</h1>
            <div class="row items-push">
              <div class="col-md-6">
                <div class="form-check form-block">
                  <input class="form-check-input" type="checkbox" v-model="market" id="example-checkbox-block1"
                    name="example-checkbox-block1">
                  <label class="form-check-label" for="example-checkbox-block1">
                    <span class="d-flex align-items-center">
                      <img class="img-avatar img-avatar48" src="@/assets/favico.png" alt="">
                      <span class="ms-2">
                        <span class="fw-bold">QRCBN Market</span>
                        <span class="d-block fs-sm text-muted" v-if="price">
                          Estimasi pendapatan per transaksi : <br/>
                          {{price | currency }} * 70% = <strong>{{ price * 0.7 | currency }}</strong>
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <p>By pressing the publish button you agree to the terms of the qrcbn marketplace.</p>
          </div>
        </div>

        <div class="action-btn">
          <v-btn color="primary darken-1" text @click="closeModal">Cancel</v-btn>
          <v-btn color="primary darken-1" type="submit">Publish</v-btn>
        </div>

      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import CoverUpdate from "../dashboard/EditNaskah/CoverUpdate.vue";
export default {
  props: ["cover", "qrcbn", "title", "book_id"],
  components: { CoverUpdate },
  data() {
    return {
      fileName: "",
      isSelecting: false,

      submitImage: false,

      selectedFile: "",
      price: "",
      books: false,
      market: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-dialog");
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.fileName = this.selectedFile.name;
    },

    publishMarket() {
      this.submitImage = true;

      var fm = new FormData();
      fm.append("book_id", this.book_id);
      fm.append("ebook", this.selectedFile);
      fm.append("price", this.price);
      fm.append("market", this.market);
      fm.append("books", this.books);

      axios
        .post("publishMarket", fm, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.data = response.data.data.user;

          this.book_id = "";
          this.selectedFile = "";
          this.price = "";

          this.$emit("close-dialog");
          this.$emit("updated-data");
        })
        .catch((error) => console.log(error))
        .finally(() => (this.submitImage = false));
    },
  },
};
</script>
